import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './scss/styles.scss'

let isCreator = false;
function setIsCreator(val) {
  isCreator = val;
}

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')

export {isCreator, setIsCreator}