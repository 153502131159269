<template>
  <v-menu v-if="auth.currentUser" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="background"
          dark
          v-bind="attrs"
          v-on="on"
      >
        Profile
      </v-btn>
    </template>
    <v-card>
      <v-card-text>

        <!-- Username -->
        <h2>Username</h2>
        <v-row>
          <v-text-field
              v-model="username"
              :rules="usernameRules"
              label="Enter new username..."
              required
          ></v-text-field>
          <v-btn @click="updateUsername()">Change</v-btn>
        </v-row>
        <br>
        <!-- Friends -->
        <h2>Add Friend</h2>
        <v-row>
          <v-text-field
              label="Enter name..."
              required
              v-model="newFriendName"
          ></v-text-field>
          <v-btn @click="addFriend()">Add</v-btn>
        </v-row>
        <br>
        <!-- List of friends -->
        <h2>Friends List</h2>
          <v-list>
            <v-list-item v-for="friend in friends">
              <v-list-item-content>
                <v-list-item-title>{{friend.username}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Streak: {{friend.streak}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <!-- <v-btn @click="removeFriend(friend)">Remove</v-btn> -->
                <v-icon @click="removeFriend(friend)">mdi-close-thick</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>


        <br>
        <br>
        <!-- Sign out -->
        <v-btn v-if="auth.currentUser" class="red" @click="signOut()">
          Sign out
        </v-btn>

      </v-card-text>
    </v-card>

    <v-snackbar
        :timeout="2000"
        v-model="snackbarSuccess"
        bottom
        color="success"
        outlined
        class="text-center"
    >
      <v-icon color="success" class="align-center">mdi-check</v-icon>
      Friend Request sent successfully!
    </v-snackbar>
    <v-snackbar
        :timeout="2000"
        v-model="snackbarError"
        bottom
        color="error"
        outlined
        class="text-center"
    >
      <v-icon color="error" class="align-center">mdi-alert-circle-outline</v-icon>
      Error. User might not exist.
    </v-snackbar>

  </v-menu>
  <v-menu offset-y :close-on-content-click="false" v-else-if="!auth.currentUser">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="background"
          dark
          v-bind="attrs"
          v-on="on"
      >
        Login
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-if="!loginMode"
              v-model="usernameRegister"
              label="Username"
              :rules="usernameRules"
              required
          ></v-text-field>

          <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
          ></v-text-field>

          <v-text-field
              v-model="password"
              label="Password"
              :type="showPassword1 ? 'text' : 'password'"
              :rules="!loginMode ? passwordRules : []"
              required
          ></v-text-field>

          <a v-if="loginMode" @click="openPasswordResetDialog">Forgot Password?</a>

          <v-dialog v-model="passwordResetDialog" max-width="600px" class="text-center">
            <v-card class="pa-2">
              <h1 class="text-center">Password Reset</h1>
              <p class="text-center">We will email you instructions on how to reset your password.</p>
              <v-card-text class="text-h5">
                <v-text-field
                    v-model="passwordResetEmail"
                    label="Enter your E-mail"
                    :rules="emailRules"
                    required
                ></v-text-field>
              </v-card-text>
              <v-btn color="green" class="align-center" text @click="passwordResetMail">Send</v-btn>
            </v-card>
          </v-dialog>

          <v-text-field
              v-if="!loginMode"
              v-model="passwordRepeat"
              label="Repeat Password"
              :type="showPassword2 ? 'text' : 'password'"
              :rules="[v => !!v || 'Password is required', v => password === passwordRepeat || 'Passwords do not match']"
              required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="info"
            class="mr-4 text-capitalize"
            @click="registerAccount"
            :plain="loginMode"
        >
          {{ !loginMode ? 'Register' : ' Create Account' }}
        </v-btn>

        <v-btn
            color="info"
            class="mr-4"
            @click="login"
            :plain="!loginMode"
        >
          Login{{ loginMode ? '' : ' instead' }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
          :timeout="2000"
          v-model="snackbarError"
          bottom
          color="error"
          outlined
          class="text-center"
      >
        <v-icon color="error" class="align-center">mdi-alert-circle-outline</v-icon>
        {{ loginSnackbarErrorText }}
      </v-snackbar>
    </v-card>
  </v-menu>
</template>

<script>
import {axiosApi as axios, getAccountToken} from "@/axios";
import {auth} from "@/firebase";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail} from "firebase/auth";
import {setIsCreator} from "@/main";

export default {
  name: "LoginMenu",
  data: () => ({
    auth,
    snackbarSuccess: false,
    snackbarError: false,
    loginSnackbarErrorText: '',
    valid: true,
    username: '',
    usernameRegister: '',
    email: '',
    password: '',
    passwordRepeat: '',
    showPassword1: false,
    showPassword2: false,
    loginMode: true,
    newFriendName: '',
    friends: [],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    usernameRules: [
      v => !!v || 'Username is required',
      v => (v && v.length <= 20) || 'Username must be less than 20 characters',
      v => (v && v.length >= 3) || 'Username must be more than 3 characters',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 6) || 'Password must be at least 6 characters long',
    ],
    passwordResetDialog: false,
    passwordResetEmail: '',
  }),
  mounted() {
    axios.interceptors.response.use()
    this.initializeToken();

    auth.onAuthStateChanged((user) => {
      if (user) {
        if(axios.defaults.headers.common['Authorization']) {
          //get username
          console.log("Getting username im mounted");
          axios.get('/profile/getData').then((response) => {
            console.log("Response: " + JSON.stringify(response.data));
            if(response.data.isCreator && response.data.isCreator === 1) {
              console.log("User is creator: " + response.data.isCreator);
              setIsCreator(true);
            }
            if(response.data.username) {
              this.username = response.data.username;
              this.friends = response.data.friends;
              console.log("Friends: " + JSON.stringify(this.friends));
              this.$emit('update-username', this.username);
            }
          }).catch((err) => {
            console.error("Error (getData): " + err);
          });
        }
      } else {
        this.$emit('update-username', "");
      }
    });
  },
  methods: {
    async initializeToken() {

      //if the user is logged in
      if(auth.currentUser) {
        console.log("User is logged in");
        await getAccountToken()
      }

      //if the user isn't logged in and there is no token in the local storage (create simple user)
      else if(!localStorage.token) {
        const response = await axios.post('/createSimpleUser').catch((err) => {
          console.error("Error (initializeToken): " + err);
        });
        if (response.status === 201) {
          console.log("Token: " + response.data.token);
          axios.defaults.headers.common['Authorization'] = response.data.token;
          localStorage.setItem('token', response.data.token);
        }
      }
    },

    //for registration of a new account a user wants to create (upgrading simple user to registered user)
    async registerAccount() {
      if (this.loginMode) {
        this.loginMode = !this.loginMode;
        return;
      }

      if (this.password === this.passwordRepeat && this.usernameRegister.length >= 3 && this.usernameRegister.length <= 20 && /.+@.+\..+/.test(this.email) && this.password.length >= 6) {
        console.log("Passwords match");
        createUserWithEmailAndPassword(auth, this.email, this.password)
            .then(async (userCredential) => {
              // Signed in
              const user = userCredential.user;

              /*
              const res = await axios.post('/auth/registerAccount', {
                idToken: await user.getIdToken(),
                username: this.usernameRegister
              }).catch((err) => {
                console.error(err);
              });
              if(res && res.status === 200) {
                console.log("User registered: " + res.data);
                this.username = this.usernameRegister;
                this.$emit('update-username', this.username);
                sendEmailVerification(user);
                await getAccountToken();
              }
               */
              const tmpIdToken = await user.getIdToken();
              const tmpUsername = this.usernameRegister;
              console.log("tmpIdToken: " + tmpIdToken);
              console.log("tmpUsername: " + tmpUsername);

              await axios.post('/auth/registerAccount', {
                idToken: tmpIdToken,
                username: tmpUsername
              })
                  .then(async (res) => {
                    console.log("User registered: " + res.data);
                    this.username = this.usernameRegister;
                    this.$emit('update-username', this.username);
                    sendEmailVerification(user);
                    await getAccountToken();
                  })
                  .catch((err) => {
                    if (err.response) { // status code out of the range of 2xx
                      console.log("Error Response:" + JSON.stringify(err.response));
                      console.log("Data :" + err.response.data);
                      console.log("Status :" + err.response.status);
                      console.log("Error text :" + err.response.error);
                      console.log("Code :" + err.response.code);
                    } else if (err.request) { // The request was made but no response was received
                      console.log(err.request);
                    } else {// Error on setting up the request
                      console.log("Error json object: " + JSON.stringify(err));
                      console.log('Error: ', err.message);
                    }
                  });

            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log("Error: " + errorCode + " " + errorMessage);
            });


      } else {
        console.log("Error during registration. Pay attention to the notes in the input fields.");
        this.loginSnackbarErrorText ="Fill out all fields correctly!"
        this.snackbarError = true;
      }
    },
    login() {
      if (!this.loginMode) {
        this.loginMode = !this.loginMode;
        return;
      }
      console.log("Login");
      signInWithEmailAndPassword(auth, this.email, this.password)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log("User logged in: " + user);
            await getAccountToken();
            console.log("Getting username im login");
            axios.get('/profile/getData').then((response) => {
              if(response.data.isCreator && response.data.isCreator === true) {
                setIsCreator(true)
              }
              if(response.data.username) {
                this.username = response.data.username;
                this.friends = response.data.friends;
                this.$emit('update-username', this.username);
              }
            }).catch((err) => {
              console.error("Error (getData): " + err);
            });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("Error: " + errorCode + " " + errorMessage);
            this.loginSnackbarErrorText = "Wrong email or password!"
            this.snackbarError = true;
          });
    },
    resetPassword() {
      if(auth) {
        sendPasswordResetEmail(auth, this.email).then(() => {
          // Password reset email sent!
          // ..
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Error: " + errorCode + " " + errorMessage);
          // ..
        });
      }
    },
    signOut() {
      signOut(auth).then(() => {
        console.log("User signed out successfully!");
        localStorage.removeItem('token');
      }).catch((error) => {
        console.error("Error: " + error);
      });
    },
    updateUsername() {
      axios.post('/profile/changeUsername', {
        username: this.username,
      }).then((res) => {
        console.log("Username updated successfully");
        console.log(res.data.newUsername)
        console.log(res);
        this.username = res.data.newUsername;
        this.$emit('update-username', res.data.newUsername);
      }).catch((err) => {
        console.error(err);
      });
    },
    addFriend() {
      //tmp
      //this.friends.push(this.newFriendName);
      //console.log("Friend added: " + this.newFriendName);

      axios.post('/profile/addFriend', {
        friendName: this.newFriendName,
      }).then((res) => {
        if(res && res.status === 200) {
          console.log("Friend request sent successfully");
          this.snackbarSuccess = true;
        } else {
          console.log("Friend request failed");
          this.snackbarError = true;
        }
      }).catch((err) => {
        console.log("Error (addFriend)");
        console.error(err);
      });
      this.newFriendName = '';
    },
    removeFriend(friend) {
      axios.delete('/profile/removeFriend', {
        data: {
          friendName: friend.username,
        }
      }).then((res) => {
        if(res !== undefined) {
          this.friends.splice(this.friends.indexOf(friend), 1);
          console.log("Friend removed: " + friend);
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    openPasswordResetDialog() {
      console.log("Open password reset dialog");
      this.passwordResetDialog = true;
    },
    passwordResetMail() {
      sendPasswordResetEmail(auth, this.passwordResetEmail).then(() => {
        // Password reset email sent!
        // ..
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error: " + errorCode + " " + errorMessage);
        // ..
      });
      this.passwordResetDialog = false;
    },
  },
  computed: {
    axiosToken() {
      return axios.defaults.headers.common['Authorization'];
    }
  }
}
</script>

<style scoped>

</style>