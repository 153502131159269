import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{style:({background: _vm.$vuetify.theme.themes[_vm.theme].background})},[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[(_vm.loggedIn)?_c(VBtn,{staticClass:"ma-2",on:{"click":_vm.goToHistoryView}},[_vm._v("Inventory")]):_vm._e(),(_vm.loggedIn)?_c(VBtn,{on:{"click":_vm.goToCreatorView}},[_vm._v("Creator View")]):_vm._e(),_c(VSpacer),_c(VToolbarTitle,[_c(VCardTitle,{on:{"click":_vm.goToHomeView}},[_c('h2',{staticStyle:{"cursor":"pointer"}},[_vm._v("RANDOM FACTS")])])],1),_c(VSpacer),(_vm.username.length>0)?_c('div',{staticClass:"ma-2 text-h5"},[_vm._v(" Hello "+_vm._s(_vm.username)+" ")]):_vm._e(),_c('LoginMenu',{on:{"update-username":_vm.usernameUpdate}})],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }