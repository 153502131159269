<template>
  <v-container class="d-flex justify-center">
    <FlipCard :factText="factText" :rarity="rarity.toString()" :streak="streak" :flipped="cardFlipped" @claim-fact="getDailyFact"/>
  </v-container>

</template>

<script>
import {axiosApi as axios} from "@/axios";

import FlipCard from "../components/FlipCard";
import App from "../App";

export default {
  components: {
    FlipCard,
  },

  name: 'HomePage',

  data: () => ({
    factShown: false,
    factText: "",
    streak: 0,
    rarity: -1,
    cardFlipped: false,
    model: false,
    //idToken: App.data().idToken
  }),
  mounted() {

  },

  methods: {
    async showFact() {
      await this.getDailyFact();
      this.factShown = true;
      this.model = !this.model;
    },
    copyFactText() {
      navigator.clipboard.writeText(this.factText);
    },
    async getDailyFact() {

      const response = await axios.post('/fact/daily').catch((err) => {
        console.error("Error (getDailyFact): " + err);
      });

      //sollte keine Probleme machen i guess
      if(!response) return;

      if (response.status === 200) {
        console.log("Fact: " + response.data.fact + " --- Rarity: " + response.data.rarity + " --- Streak: " + response.data.streak);
        this.factText = response.data.fact;
        this.rarity = response.data.rarity;
        this.streak = response.data.streak;
        this.cardFlipped = true;
      } else {
        console.error("Error (getDailyFact) (response != 200): " + response.status);
      }
    },

    //tmp
    async firstGetRequest() {
      const response = await axios.get('/').catch((err) => {
        console.error(err);
      });
      if (response.status === 200) console.log(response.data);
    },
  }
}
</script>

