<template>
  <v-container>
    <v-card v-if="connectionError" class="red--text">CONNECTION ERROR</v-card>
    <div v-if="!authVar">
      <v-card-text class="red--text">You are not authorized as a creator!</v-card-text>
    </div>
    <div v-if="authVar">
      <u><h1 class="text-center">Creator Page</h1></u>
      <h3>Enter a new fact to add to the database!</h3>
      <v-text-field v-model="newFactText" label="Enter a new completely random fact" @keydown.enter="sendNewFact" append-outer-icon="mdi-check"/>
      <v-text-field v-model="newFactRarity" label="rarity (0: common, 1: rare, 2: epic, 3: legendary)" @keydown.enter="sendNewFact" append-outer-icon="mdi-check"/>
      <v-dialog v-model="dialog" max-width="600px" @keydown.enter="confirmFact" @keydown.delete="cancelFact" class="text-center">
        <h1 class="text-center">Correct?</h1>
        <v-card>
          <v-card-text class="text-h4">
            {{newFactText}} <br>
            rarity: {{newFactRarity}}
          </v-card-text>
          <v-btn color="green" class="align-center" text @click="confirmFact">Confirm</v-btn>
        </v-card>
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
            v-if="progressBar"
        ></v-progress-linear>
      </v-dialog>
      <v-btn color="green" class="justify-center" text @click="sendNewFact">Send</v-btn>

      <br>
      <br>
      <h3>Your own facts:</h3>
      <v-row>
        <v-col
            v-for="fact in ownFacts"
            cols="12"
            sm="6"
            md="4"
            lg="3"
        >
          <v-card class="pa-3">
            Fact: {{fact.fact}} <br>
            Rarity: {{fact.rarity === 0 ? "common" : fact.rarity === 1 ? "rare" : fact.rarity === 2 ? "epic" : "legendary"}} ({{fact.rarity}}) <br>
            <v-btn
                class="ma-2"
                text
                icon
                color="blue lighten-2"
                @click="showEditDialog(fact)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card>
        </v-col>
        <v-dialog v-model="factEditDialog" max-width="600px" class="text-center">
          <v-card class="pa-2">
            <h1 class="text-center">Edit fact:</h1>
            <v-card-text class="text-h4">
              <v-textarea v-model="editFactText" label="Edit your fact" />
              <v-text-field v-model="editFactRarity" label="rarity (0: common, 1: rare, 2: epic, 3: legendary)" />
            </v-card-text>
            <v-btn color="green" class="align-center" text @click="editFact">Confirm</v-btn>
          </v-card>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
              v-if="progressBar"
          ></v-progress-linear>
        </v-dialog>
      </v-row>
      <br>
      <h3>All facts in the database:</h3>
      <h4>Send messages to the authors for improvement suggestions!</h4>
      <v-row>
        <v-col
            v-for="fact in allOtherFacts"
            cols="12"
            sm="6"
            md="4"
            lg="3"
        >
          <v-card class="pa-3">
            <b><u>by {{fact.username === null ? "anonymous" : fact.username}}:</u></b> <br><br>
            Fact: {{fact.fact}} <br> <br>
            Rarity: {{fact.rarity === 0 ? "common" : fact.rarity === 1 ? "rare" : fact.rarity === 2 ? "epic" : "legendary"}} ({{fact.rarity}}) <br>
            <v-btn
                class="ma-2"
                text
                icon
                color="blue lighten-2"
                @click="showMessageDialog(fact)"
            >
              <v-icon>mdi-email-arrow-right-outline</v-icon>
            </v-btn>
          </v-card>
        </v-col>
        <v-dialog v-model="authorMessageDialog" max-width="600px" class="text-center">
          <v-card class="pa-2">
            <h1 class="text-center">Send Message:</h1>
            <v-card-text class="text-h5">
              Fact: <b>{{messageFactText}}</b> <br> <br>
              Rarity: {{messageFactRarity === 0 ? "common" : messageFactRarity === 1 ? "rare" : messageFactRarity === 2 ? "epic" : "legendary"}} ({{messageFactRarity}}) <br> <br>
              Author: {{messageAuthor}} <br>
              <br>
              <v-textarea outlined v-model="messageText" label="Enter your message..." />
            </v-card-text>
            <v-btn color="green" class="align-center" text @click="sendMessageToAuthor">Send</v-btn>
          </v-card>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
              v-if="progressBar"
          ></v-progress-linear>
        </v-dialog>
      </v-row>
    </div>
    <v-snackbar
        :timeout="1000"
        v-model="snackbarSuccess"
        bottom
        color="success"
        outlined
        class="text-center"
    >
      <v-icon color="success" class="align-center">mdi-check</v-icon>
      success!
    </v-snackbar>
    <v-snackbar
        :timeout="3000"
        v-model="snackbarError"
        bottom
        color="error"
        outlined
        class="text-center"
    >
      <v-icon color="error" class="align-center">mdi-alert-circle-outline</v-icon>
      Error! Try again later...
    </v-snackbar>
  </v-container>
</template>

<script>
import {axiosApi as axios} from "@/axios";
import FlipCard from "@/components/FlipCard.vue";

  export default {
    components: {FlipCard},
    data: () => ({
      customToken: "",
      model: "",
      authVar: false,
      newFactText: "",
      newFactRarity: 0,
      editFactText: "",
      editFactRarity: 0,
      editFactId: 0,
      messageFactText: "",
      messageFactRarity: 0,
      messageFactId: 0,
      messageAuthor: "",
      messageAuthorId: 0,
      messageText: "",
      dialog: false,
      factEditDialog: false,
      authorMessageDialog: false,
      connectionError: false,
      progressBar: false,
      snackbarSuccess: false,
      snackbarError: false,
      ownFacts: [],
      allOtherFacts: [],
      rules: {
        required: v => !!v || 'Rarity is required',
        rarityRule: v => (v && v >= 0 && v <= 3) || 'Rarity must be a number between 0 and 3'
      }
    }),
    mounted() {
      //after 1 second, do page initialization
      setTimeout(() => {
        this.pageInitialization();
      }, 1000);
    },
    methods: {
      async pageInitialization() {
        const res = await axios.get('/creator/getData').catch((err) => {
          console.error("Connection error!" + err);
        });
        if (res && res.status === 200) {
          console.log("Successfully initialized creator page!");
          this.authVar = true;
          console.log(res.data);

          this.ownFacts = res.data.selfCreatedFacts;
          this.allOtherFacts = res.data.allOtherFacts;
        } else {
          console.log("Error! You might not be an authorized creator!");
          this.authVar = false;
        }
      },
      async sendNewFact() {
        if(this.newFactRarity < 0) {
          this.newFactRarity = 0;
        } else if(this.newFactRarity > 3) {
          this.newFactRarity = 3;
        }
        this.dialog = true;
      },
      async confirmFact() {
        this.progressBar = true;
        console.log("Fact: " + this.newFactText);
        const res = await axios.post('/creator/newFact', {fact: this.newFactText, rarity: this.newFactRarity}).catch((err) => {
          console.error("Connection error!" + err);
          this.connectionError = true;
        });
        if (res.status === 201) {
          console.log("New fact successfully added!");
          this.snackbarSuccess = true;
          this.newFactText = "";
          this.newFactRarity = 0;
          this.pageInitialization()
        }
        this.dialog = false;
        this.progressBar = false;
      },
      cancelFact() {
        this.dialog = false;
      },
      showEditDialog(fact) {
        this.editFactText = fact.fact;
        this.editFactRarity = fact.rarity;
        this.editFactId = fact.id;
        this.factEditDialog = true;
      },
      async editFact() {
        this.factEditDialog = false;
        this.progressBar = true;
        const res = await axios.post('/creator/editFact', {id: this.editFactId, fact: this.editFactText, rarity: this.editFactRarity}).catch((err) => {
          console.error("Connection error!" + err);
          this.connectionError = true;
        });
        this.progressBar = false;
        if (res && res.status === 200) {
          console.log("Fact successfully edited!");
          this.snackbarSuccess = true;
          this.pageInitialization()
        } else {
          console.log("Error! Fact could not be edited!");
          this.snackbarError = true;
        }
        this.editFactText = "";
        this.editFactRarity = 0;
        this.editFactId = 0;
      },
      showMessageDialog(fact) {
        this.messageFactText = fact.fact;
        this.messageFactRarity = fact.rarity;
        this.messageFactId = fact.id;
        this.messageAuthor = fact.username;
        this.messageAuthorId = fact.authorId;
        this.authorMessageDialog = true;
      },
      async sendMessageToAuthor() {
        console.log("Send message to author!");
        const res = await axios.post('/creator/sendMessage', {factId: this.messageFactId, authorId: this.messageAuthorId, message: this.messageText}).catch((err) => {
          console.error("Connection error!" + err);
          this.connectionError = true;
        });
        if (res && res.status === 200) {
          console.log("Message successfully sent!");
          this.snackbarSuccess = true;
        } else {
          console.log("Error! Message could not be sent!");
          this.snackbarError = true;
        }
        this.authorMessageDialog = false;
        this.messageFactText = "";
        this.messageFactRarity = 0;
        this.messageFactId = 0;
        this.messageAuthor = "";
        this.messageAuthorId = 0;
        this.messageText = "";
      }
    }
  }
</script>