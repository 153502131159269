<template>
  <div class="wrapperFlipCard">
    <v-img
        :src="require('../assets/placeholder.png')"
        class="rounded-xl transparent"
        max-height="50vh"
        max-width="50vh"
    >

      <div class="flipCard">

        <!-- ?? -->
        <div class="flipCardCheckbox">
        <input type="checkbox" id="card1" class="" aria-hidden="true">

        </div>
        <!-- ?? -->

        <div class="content" :flipped="flipped">
          <div class="frontFlipCard" :style="`background-image: url(${require('@/assets/logo.png')})`">
            <div class="inner">

              <label for="card1" class="flipCardBtn" aria-hidden="true" @click="claimFact">
                Claim your daily fact!
              </label>
            </div>
          </div>
          <div class="backFlipCard">
            <div class="inner">

              <div class="factText">
                <p>{{ factText }}</p>
              </div>
              <div class="flipCardHeadline">Your today's fact (Rarity {{rarity}}) :</div>
              <div class="price">Streak: {{ streak }}</div>
              <label for="card1" class="flipCardBtn return" aria-hidden="true">
                <i class="fas fa-arrow-left"></i>
              </label>
            </div>
          </div>
        </div>
      </div>

    </v-img>
  </div>

  <!--
  <vue-flip width="50vh" height="50vh" v-model="model" class="card">
    <template v-slot:front class="front">
      <v-img
          :src="require('../assets/logo.png')"
          class="rounded-xl"
          max-height="50vh"
          max-width="50vh"
      >
        <v-btn class="transparent" width="100%" height="100%" @click="showFact"/>
      </v-img>
    </template>
    <template v-slot:back class="back justify-center">

      <v-card class="rounded-xl" width="100%" height="100%" elevation="0">
        <v-card-title class="justify-center">
          <h2 class="text-center">Your Daily Fact:</h2>
        </v-card-title>
        <v-card-text class="justify-center">
          <h2 class="text-center blue--text">{{factText}}</h2>
        </v-card-text>
        <v-card-text class="justify-center">
          <h2 class="text-center">Your Streak: {{streak}}</h2>
        </v-card-text>
      </v-card>
    </template>
  </vue-flip>
  -->
</template>

<!--
      <v-row class="justify-center" key="a">
          <v-col cols="4" class="my-2 d-flex justify-center">
            <vue-flip width="50vh" height="50vh" v-model="model" class="card">
              <template v-slot:front class="front">
                <v-img
                    :src="require('../assets/logo.png')"
                    class="rounded-xl"
                    max-height="50vh"
                    max-width="50vh"
                >
                  <v-btn class="transparent" width="100%" height="100%" @click="showFact"/>
                </v-img>
              </template>
              <template v-slot:back class="back justify-center">

                  <v-card class="rounded-xl" width="100%" height="100%" elevation="0">
                    <v-card-title class="justify-center">
                      <h2 class="text-center">Your Daily Fact:</h2>
                    </v-card-title>
                    <v-card-text class="justify-center">
                      <h2 class="text-center blue--text">{{factText}}</h2>
                    </v-card-text>
                    <v-card-text class="justify-center">
                      <h2 class="text-center">Your Streak: {{streak}}</h2>
                    </v-card-text>
                  </v-card>
              </template>
            </vue-flip>
          </v-col>
      </v-row>
-->

<script>
import VueFlip from 'vue-flip';

export default {
  name: 'FlipCard',
  components: {
    'vue-flip': VueFlip
  },
  data: () => ({
    modelCard: false
  }),
  props: {
    factText: {
      type: String,
      required: true
    },
    streak: {
      type: Number,
      required: false
    },
    rarity : {
      type: String,
      required: true
    },
    flipped : {
      type: Boolean,
      required: true
    }
  },
  methods: {
    claimFact() {
      this.$emit('claim-fact');
    },
  }
}
</script>