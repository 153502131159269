// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = process.env.NODE_ENV === 'production' ?
    {
    apiKey: "AIzaSyD9CESkn5WOyIHKm7C42gcafmMvmuGCzqs",
    authDomain: "random-facts---production.firebaseapp.com",
    projectId: "random-facts---production",
    storageBucket: "random-facts---production.appspot.com",
    messagingSenderId: "765576131314",
    appId: "1:765576131314:web:a21bf212f304cc0bf6166b",
    measurementId: "G-GBP5DGRVP5"
    } : {
    apiKey: "AIzaSyCXw9IcDt9JxGwL3bq6nNbAONAIZlFvrtw",
    authDomain: "randomfact-8cf1b.firebaseapp.com",
    projectId: "randomfact-8cf1b",
    storageBucket: "randomfact-8cf1b.appspot.com",
    messagingSenderId: "617995421208",
    appId: "1:617995421208:web:ed275c6f8d46d82238e56d",
    measurementId: "G-W94TJ2J5TV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
export { auth }