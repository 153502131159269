<template>
  <h3>{{infoText}}</h3>
</template>

<script>
import {axiosApi as axios, getAccountToken} from "@/axios";
export default {
  name: "FriendRequestView",

  data: () => ({
    infoText: "",
  }),

  async mounted() {
    await axios.put('/friendrequest/'+this.$route.params.id).catch((err) => {
      console.error("Error: "+err);
      this.infoText = "Error: " + err;
    }).then((response) => {
      if(response !== undefined) {
        this.infoText = response.data;
        //after 3 seconds, redirect to main page
        setTimeout(() => {
          this.$router.push('/');
        }, 3000);
      } else {
        this.infoText = "Error, try again later..."
      }
    });
  }
}
</script>

<style scoped>

</style>