import axios from 'axios';
import {auth} from "@/firebase";

console.log('NODE_ENV', process.env.NODE_ENV);

const axiosApi = axios.create({
    ...(process.env.NODE_ENV === 'production' ?
        {baseURL: `${location.protocol}//${location.hostname}:${location.port}/`} :
        {baseURL: `${location.protocol}//${location.hostname}:5000/`}),
});
axiosApi.interceptors.response.use(response => response, async error => {
    if (error.response.status === 401 && error.response.data.code === 'TOKEN_EXPIRED') {
        if(auth.currentUser === null) {
            console.log("Please log in to refresh token! (your token belonged to a registered account)");
        } else {
            console.log("Token expired, requested new one...");
            await getAccountToken();
            let config = error.config;
            config.headers['Authorization'] = axiosApi.defaults.headers.common['Authorization'];
            return axiosApi.request(config);
        }
    }
});

async function getAccountToken() {
    const idToken = await auth.currentUser.getIdToken()
    const accountToken = (await axiosApi.get('/accountToken', {
        headers: {
            Authorization: idToken
        }
    })
        .catch(error => {
            console.log(error);
        })).data.accountToken;
    if(accountToken) {
        console.log("Received account token: " + accountToken);
        axiosApi.defaults.headers.common['Authorization'] = accountToken
        localStorage.setItem('token', accountToken);
    } else {
        console.error("No account token received!");
    }
}

export {axiosApi, getAccountToken}