import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import CreatorView from "@/views/CreatorView.vue";
import ProfileOverview from "@/views/ProfileOverview.vue";
import FriendRequestView from "@/views/FriendRequestView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Random Facts',
    component: HomePage
  },
  {
    path: '/creator',
    name: 'Random Facts - Creator',
    component: CreatorView
  },
  {
    path: '/profile',
    name: 'Random Facts - Profile',
    component: ProfileOverview,
  },
  {
    path: '/friends/:id',
    name: 'Random Facts - Friend Requests',
    component: FriendRequestView,
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name
  next()
})

export default router
