<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">

    <v-app-bar
        app
        color="primary"
        dark
    >

      <v-btn class="ma-2" v-if="loggedIn" @click="goToHistoryView">Inventory</v-btn>
      <v-btn v-if="loggedIn" @click="goToCreatorView">Creator View</v-btn>

      <v-spacer></v-spacer>

      <v-toolbar-title>

        <v-card-title @click="goToHomeView"><h2 style="cursor: pointer">RANDOM FACTS</h2></v-card-title>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- Text for logged in user -->
      <div class="ma-2 text-h5" v-if="username.length>0">
        Hello {{username}}
      </div>


      <LoginMenu @update-username="usernameUpdate"/>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import HomePage from "./views/HomePage";
import LoginMenu from "@/components/LoginMenu";
import {axiosApi as axios} from "@/axios";
import {auth} from "@/firebase";

export default {
  name: 'App',

  components: {
    LoginMenu,
  },

  data: () => ({
    username: "",
    loggedIn: false,
  }),

  methods: {
    usernameUpdate(username) {
      this.username = username;
    },
    goToHistoryView() {
      this.$router.push({path: '/profile'});
    },
    goToCreatorView() {
      this.$router.push({path: '/creator'});
    },
    goToHomeView() {
      console.log("go to home view");

      if(this.$route.path === '/') return;

      this.$router.push({path: '/'});
    },
  },

  mounted() {
    if(localStorage.token) axios.defaults.headers.common['Authorization'] = localStorage.token;
    auth.onAuthStateChanged((user) => {
      if(user) {
        this.loggedIn = true;
      }
    });
  },

  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
};
</script>

