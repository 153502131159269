import { render, staticRenderFns } from "./LoginMenu.vue?vue&type=template&id=5fb1e2d0&scoped=true"
import script from "./LoginMenu.vue?vue&type=script&lang=js"
export * from "./LoginMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb1e2d0",
  null
  
)

export default component.exports