import { render, staticRenderFns } from "./ProfileOverview.vue?vue&type=template&id=63784928&scoped=true"
import script from "./ProfileOverview.vue?vue&type=script&lang=js"
export * from "./ProfileOverview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63784928",
  null
  
)

export default component.exports