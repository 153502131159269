import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

// Get the media query containing the user's preferred theme
const mq = window.matchMedia('(prefers-color-scheme: dark)')

// Instantiate Vuetify using this theme
const vuetify = new Vuetify({
    theme: { dark: mq.matches,
        themes: {
            dark: {
                primary: colors.indigo.lighten1,
                background: '#2B2B2B'
            }
        }
    },
    font: {
        family: 'Arial'
    }
})

// Update the app theme every time the preferred theme is changed
// (potentially by the user, timed night mode, ...)
mq.addEventListener('change', (e) => {
    vuetify.framework.theme.dark = e.matches
})

export default vuetify