<template>

  <v-container>
    <v-row>
      <v-col
          v-for="fact in ownedFacts"
          cols="12"
          sm="6"
          md="4"
          lg="3"
      >
        <FlipCard :factText="fact.fact" :rarity="fact.rarity.toString()" :flipped="true"/>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import FlipCard from "@/components/FlipCard.vue";
import {axiosApi as axios} from "@/axios";

export default {
  name: "ProfileOverview",

  components: {
    FlipCard,
  },

  mounted() {
    this.getOwnedFacts()
  },

  data: () => ({
    ownedFacts: [],
  }),

  methods: {
    async getOwnedFacts() {
      //wait until token in axios is set
      while(!axios.defaults.headers.common['Authorization']) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const response = await axios.get('/fact/owned').catch((err) => {
        console.error(err);
        return null;
      });

      if(!response) {
        console.error("Error (getOwnedFacts): response is null");
        return;
      }

      this.ownedFacts = response.data;
    }
  }
}
</script>

<style scoped>

</style>